function reformatIdeas(target = 'msl-idealist', maxcols = 12, cols = 4, defaultimage = 'https://code.derbyunion.co.uk/usdev/images/us-scribble.svg') {
  function formatIdeas(idealist) {
    // let html = '<ul class="row">'
    let html = ''
    idealist.forEach(mslIdea => {
      html += `
      <li class="ideaforumidea ${mslIdea.archived ? `ideaforumidea-archived ` : " "} col-${maxcols} col-md-${cols}" id="${mslIdea.id}">
          <a class="ideaforumidea-wrapper" href="https://www.derbyunion.co.uk/change/ideas/${mslIdea.link.match(/\?(.*)/g)}">
              <div class="ideaforumidea-image"></div>
              <div class="ideaforumidea-info">
                  <div class="ideaforumidea-title">${mslIdea.title}</div>
                  <div class="ideaforumidea-meta">
                    <div class="ideaforumidea-submitdate">Submitted ${mslIdea.submitdate}</div>
                    ${mslIdea.expirydate ? `<div class="ideaforumidea-expirydate">${mslIdea.expirydate}</div>` : ""}
                    <ul class="ideaforumidea-tags">
                      ${mslIdea.tags.map(tag => `<li class="ideaforumidea-tag__${tag.toLowerCase().replace(/\s/g, "-")}">${tag}</li>`).join("")}
                    </ul>
                    <div class="ideaforumidea-voting">
                      <div class="ideaforumidea-votingscore">${mslIdea.score}</div>
                      <div class="ideaforumidea-votingvotes">${mslIdea.votes}</div>
                    </div>
                    <div class="ideaforumidea-commentcount">${mslIdea.commentcount.join(" ")}</div>
                  </div>
              </div>
          </a>
      </li>
      `
    })
    // html += '</ul>'
    return html
  }

  Array.from(document.querySelectorAll(`.${target}`)).forEach(list => {
    const mslideas = [].slice.call(list.querySelectorAll(`.msl-idea`))
    const ourideas = mslideas.map(idea => {
      const id = idea.id
      const status = idea.querySelector('.msl-idea-status').innerText
      const archived = idea.classList.contains('archived')
      const score = parseInt(idea.querySelector('.votes .number').innerText, 10)
      const votes = parseInt(idea.querySelector('.voters .number').innerText, 10)
      const title = idea.querySelector('.msl-idea-title').innerText
      const link = idea.querySelector('.msl-idea-title a').href
      const submitdate = idea.querySelector('.msl-idea-submitdate').innerText
      const expirydate = idea.querySelector('.msl-idea-expirydate') !== null ? idea.querySelector('.msl-idea-expirydate').innerText : null
      const submitter = idea.querySelector('.msl-idea-expirydate') !== null ? idea.querySelector('.msl-idea-expirydate').innerText : "Anonymous"
      const tags = Array.from(idea.querySelectorAll('.msl-idea-tags li')).map(tag => tag.innerText)
      const commentcount = [idea.querySelector('.msl-idea-comments a').innerText.replace(idea.querySelector('.msl-idea-comments a span').innerText, ""), idea.querySelector('.msl-idea-comments a span').innerText]

      return {
        id,
        status,
        archived,
        score,
        votes,
        title,
        link,
        submitdate,
        expirydate,
        submitter,
        tags,
        commentcount,
      }
    })
    list.classList.add("row")
    list.innerHTML = formatIdeas(ourideas)
  })
}
